function sortArray( a, b,order ) {
  switch(order){
    default:
            return 0
    case "nameDESC":
      if ( a.surname < b.surname ){
        return -1;
      }
      if ( a.surname > b.surname ){
        return 1;
      }
      return 0;
    case "nameASC":
      if ( a.surname > b.surname ){
        return -1;
      }
      if ( a.surname < b.surname ){
        return 1;
      }
      return 0;
      case "hasAnswered":
        if ( a.hasAnswered ){
          return -1;
        }
        else {
          return 1;
        }
        case "partyDESC":
          if ( a.party.abbreviation < b.party.abbreviation ){
            return -1;
          }
          if ( a.party.abbreviation > b.party.abbreviation ){
            return 1;
          }
          return 0;
        case "partyASC":
          if ( a.party.abbreviation > b.party.abbreviation ){
            return -1;
          }
          if ( a.party.abbreviation < b.party.abbreviation ){
            return 1;
          }
          return 0;  
          
  }
}

export function sortPersons(persons,order){
    const arr=[...persons];
    return arr.sort((a,b)=>sortArray(a,b,order));
}