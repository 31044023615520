import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sortPersons } from '../../services/sortPersons';
import { filterFunction } from '../../services/filterPersons';

// First, create the thunk
const fetchPersons = createAsyncThunk(
  'persons/fetchPersons',
  async (value, thunkAPI) => {
    const personState = thunkAPI.getState().person;
    const sortedPersons =  sortPersons(personState.entities,personState.sort)
    return sortedPersons;
  }
)

const filterPersons = createAsyncThunk(
  'persons/filterPersons',
  async (value, thunkAPI) => {
    const personState = thunkAPI.getState().person;
    return filterFunction(personState.allEntities,value)
  }
)

const personsSlice = createSlice({
    name: 'persons',
    initialState: {
        allEntities: [],
        entities: [],
        sort: "hasAnswered",
        counter: 0,
        loading: "idle",
    },
    reducers: {
      changePersonState(state,action){
        state[action.payload.key] = action.payload.value
      }
    }, 
    extraReducers: {
      // Add reducers for additional action types here, and handle loading state as needed
      [fetchPersons.fulfilled]: (state, action) => {
        // Add user to the state array
        state.entities = (action.payload)
      },
      [filterPersons.fulfilled]: (state, action) => {
        // Add user to the state array
        state.entities = (action.payload)
      }
    }
  })

export {fetchPersons,filterPersons}

export const { changePersonState} = personsSlice.actions

export default personsSlice.reducer