import { configureStore } from '@reduxjs/toolkit'
import personReducer from './slices/personReducer';
import languageReducer from './slices/languageReducer';


export default configureStore({
  reducer: {
    person : personReducer,
    language : languageReducer
  }
})