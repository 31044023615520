import { createSlice } from '@reduxjs/toolkit'

const languageSlice = createSlice({
    name: 'languages',
    initialState: {
        language : 'SWE',
    
},
reducers: {
    changeLanguageState(state,action){
        state[action.payload.key] = action.payload.value
    }
}
}
)

export const { changeLanguageState } = languageSlice.actions
export default languageSlice.reducer
